import axios from 'axios';
import {
  FormAnswerSignatureState,
  PdfLink,
  SignatureBody,
  SignatureIndex,
  SignatureIndexParams,
  SignatureRequestResponse,
  SignatureResponse,
  UserSignatureState,
} from '../types/signature';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

// These requests are only used for account signature configuration.
// When the account config is migrated to React, this will be included
// in the account config API.

async function getSignature(type: string) {
  const response = await axios.get<SignatureResponse>(
    `signatures/${type === 'user' ? 'mobile_user_config' : 'account_config'}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function updateAccountSignature(body: SignatureBody, userId: string) {
  const response = await axios.post(`users/update_react/${userId}`, body, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getUserSignatureState() {
  const response = await axios.get<UserSignatureState>('signatures/mobile_user/state', {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getFormAnswerSignatureState(formAnswerId: string) {
  const response = await axios.get<FormAnswerSignatureState>(
    `signatures/form_answer/${formAnswerId}`,
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

async function signFormAnswer(formAnswerId: string) {
  const response = await axios.put(`signatures/form_answer/${formAnswerId}/sign`, null, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getSignatureIndex(params: SignatureIndexParams) {
  const response = await axios.get<SignatureIndex>('signatures/signatures_index', {
    withCredentials: true,
    params,
  });
  const { data } = response;
  return data;
}

async function getSignatureRequests(
  search?: string,
  page?: number,
  status?: 'pending' | 'completed',
) {
  const response = await axios.get<SignatureRequestResponse>(
    `signatures/requests_index?search=${search ?? ''}&page=${page + 1}&status=${status ?? ''}`,
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

async function shareSignedPdf(requestId: number, emails: string[]) {
  const response = await axios.post(
    `signatures/${requestId}/share_email`,
    { emails },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function deleteSignatureRequest(requestId: number) {
  const response = await axios.delete(`signatures/requests/${requestId}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getSignedPdfUrl(requestId: number) {
  const response = await axios.get<PdfLink>(`signatures/${requestId}/pdf_link`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function bulkSign(requestIds: number[]) {
  const response = await axios.post(
    'signatures/requests/bulk_sign',
    { requests_ids: requestIds },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

export const signatureApi = {
  getSignature,
  updateAccountSignature,
  getUserSignatureState,
  getFormAnswerSignatureState,
  signFormAnswer,
  getSignatureRequests,
  getSignatureIndex,
  shareSignedPdf,
  bulkSign,
  getSignedPdfUrl,
  deleteSignatureRequest,
};
