import { CircularProgress, Skeleton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Suspense, lazy, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { NewMobileUserDataContextProvider } from './components/MobileUsers/mobileUsersNew/newMobileUserDataContextProvider';
import { TaskAssignerProvider } from './components/TaskAssignerNew/TaskAssignerCreate/TaskAssignerProvider';
import { LoadingScreen } from './components/uiComponents/loadingScreen';
import { LangContext } from './context.js';
import { English } from './lang/en.js';
import { Spanish } from './lang/es.js';
import { Portuguese } from './lang/pt.js';
import { FirestoreProvider, FormBuilderProvider } from './providers';
import { UserDataProvider } from './providers/UserDataProvider';
import store from './store/store';
import { theme } from './style/theme';
import { InitializeTrackers } from './utils/eventTracker';
import { getQueryParams } from './utils/utils';
import { AnswerViewProvider } from './components/AnswerView/AnswerViewContextProvider';

function App() {
  const queryParams = getQueryParams();
  const langParam = queryParams.lang;
  const langDict: any = { en: English, es: Spanish, pt: Portuguese };
  const lang = langDict[langParam];
  const FormBuilder = lazy(() => import('./components/FormBuilder/formBuilder'));
  const TaskForms = lazy(() => import('./components/TaskForms/taskForms'));
  const MobileUsers = lazy(() => import('./components/MobileUsers/mobileUsers'));
  const MobileUsersNew = lazy(() => import('./components/MobileUsers/mobileUsersNew'));
  const Wizard = lazy(() => import('./components/Wizard/Wizard'));
  const WizardFinish = lazy(() => import('./components/WizardFinish/WizardFinish'));
  const Templates = lazy(() => import('./components/Templates/templates'));
  const TemplateView = lazy(() => import('./components/Templates/newTemplateView'));
  const WebForm = lazy(() => import('./components/NewWebForms/webForm'));
  const Login = lazy(() => import('./components/Login/Login'));
  const SetProfile = lazy(() => import('./components/MobileUsers/setProfile/setProfile'));
  const FormEmailBuilder = lazy(() => import('./components/FormEmailBuilder/FormEmailBuilder'));
  const AccountConfig = lazy(() => import('./components/AccountConfig/AccountConfig'));
  const Notifications = lazy(() => import('./components/Notifications/Notifications'));
  const TaskAssigner = lazy(() => import('./components/TaskAssignerNew/TaskAssignerIndex'));
  const Pricing = lazy(() => import('./components/Pricing/Pricing'));
  const Findings = lazy(() => import('./components/Findings/Findings'));
  const FindingsSettings = lazy(() => import('./components/Findings/FindingsSettings'));
  const FindingType = lazy(() => import('./components/Findings/FindingTypes/FindingType'));
  const Billing = lazy(() => import('./components/Billing/Billing'));
  const CreateOrEditTaskAssigned = lazy(
    () => import('./components/TaskAssignerNew/TaskAssignerCreate/CreateOrEditTaskAssigned'),
  );
  const ModifySubscription = lazy(
    () => import('./components/Billing/ActiveSubscription/ModifySubscription'),
  );
  const WebHooksNew = lazy(() => import('./components/WebHooks/webHooksNew'));
  const AnswerView = lazy(() => import('./components/AnswerView/AnswerView'));
  const VerifySignature = lazy(
    () => import('./components/ElectronicSignature/VerifySignature/VerifySignature'),
  );
  const ElectronicSignature = lazy(
    () => import('./components/ElectronicSignature/ElectronicSignature'),
  );
  const FindingShared = lazy(() => import('./components/Findings/FindingShared/FindingShared'));
  const Resume = lazy(() => import('./components/Resume/Resume'));

  useEffect(() => {
    InitializeTrackers();
  }, []);

  return (
    <LangContext.Provider value={lang}>
      <ThemeProvider theme={theme}>
        <UserDataProvider>
          <ReduxProvider store={store}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <FirestoreProvider>
                <Router>
                  <Routes>
                    <Route
                      path='/taskassigner'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <TaskAssigner />
                        </Suspense>
                      }
                    />
                    <Route
                      path='task_assigns/new'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <TaskAssignerProvider>
                            <CreateOrEditTaskAssigned />
                          </TaskAssignerProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='task_assigns/:taskId/edit'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <TaskAssignerProvider>
                            <CreateOrEditTaskAssigned />
                          </TaskAssignerProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='/webforms'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <WebForm />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/login'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Login />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/wizard'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Wizard />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/wizard_finish'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <WizardFinish />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/taskForms'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <TaskForms />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/electronic_signature/verify'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <VerifySignature />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/mobile_users'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <MobileUsers />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/templates'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Templates />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/template_view'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <TemplateView />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/mobile_users/new'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <NewMobileUserDataContextProvider>
                            <MobileUsersNew />
                          </NewMobileUserDataContextProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='/mobile_users/edit'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <NewMobileUserDataContextProvider>
                            <MobileUsersNew />
                          </NewMobileUserDataContextProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='/formBuilder'
                      element={
                        <Suspense
                          fallback={
                            <>
                              <Skeleton
                                variant='text'
                                width={600}
                                height={50}
                              />
                              <Skeleton
                                variant='rectangular'
                                width={600}
                                height={170}
                              />
                            </>
                          }
                        >
                          <FormBuilderProvider>
                            <FormBuilder />
                          </FormBuilderProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='/form_email_builder'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FormEmailBuilder />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/set_profile'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <SetProfile />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/account_config'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <AccountConfig />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/notifications'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Notifications />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/billing'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Billing />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/pricing'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Pricing />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/modify_subscription'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <ModifySubscription />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/findings'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Findings />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/findings/settings'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FindingsSettings />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/findings/types/new'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FindingType />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/findings/types/:typeId/edit'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FindingType edit />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/findings/share/:findingId'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <FindingShared />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/webhooks/new'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <WebHooksNew />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/webhooks/edit'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <WebHooksNew />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/answer_view'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <AnswerViewProvider>
                            <AnswerView />
                          </AnswerViewProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path='/resume_empty'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <Resume />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/signatures'
                      element={
                        <Suspense fallback={<LoadingScreen />}>
                          <ElectronicSignature />
                        </Suspense>
                      }
                    />
                  </Routes>
                </Router>
              </FirestoreProvider>
            </SnackbarProvider>
          </ReduxProvider>
        </UserDataProvider>
      </ThemeProvider>
    </LangContext.Provider>
  );
}

export default App;
